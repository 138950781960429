<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <h2 class="text-h3 text-center black--text">My Fitness</h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <h4
          class="text-subtitle-1 text-center black--text"
        >Sometimes I work out. Sometimes when I work out I get it on camera.</h4>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-lg-4 col-md-6 col-sm-12" v-for="embed in embeds" :key="embed">
        <instagram-embed :url="embed" :appID="instaAppID" :clientToken="instaClientToken" :max-width="400" :hide-caption="true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstagramEmbed from "vue-instagram-embed";
export default {
  name: "Fitness",
  components: {
    InstagramEmbed
  },
  data: () => ({
    instaClientToken : "38cd60e15b8d7cf1be3e70ee6bfeeb5c",
    instaAppID : "206690097798263",
    embeds: [
      "https://www.instagram.com/p/Bkp1KFwHrO3/",
      "https://www.instagram.com/p/BdcoEwnFo45/",
      "https://www.instagram.com/p/BbCBDb_Ftw_/",
      "https://www.instagram.com/p/BiCCprShfWg/",
      "https://www.instagram.com/p/BfIw5DglN4j/",
      "https://www.instagram.com/p/Bj9oNXhBsj6/",
      "https://www.instagram.com/p/BfqR9g5lNYl/",
      "https://www.instagram.com/p/Bc9tnYGlpvi/",
      "https://www.instagram.com/p/BtbWKcdlHtA/"
    ]
  })
};
</script>

<style></style>
